import { Text, Container, Flex, BoxProps, useBreakpointValue } from '@chakra-ui/react'
import styles from '@styles/projects.module.css'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC, useContext, useEffect, useMemo, useState } from 'react'

import { extractString } from '@miimosa/common'
import { PinMapOutline, usePlatform } from '@miimosa/design-system'

import { QueryFilter } from '@components'
import SearchBar from '@components/Header/SearchBar'
import getFilters from '@helpers/getFilters'

import { FiltersContext } from './FiltersProvider'

interface Props {
  projectsCount: number
  withSearchBar?: boolean
}

const HeadFilter: FC<Props & BoxProps> = ({ withSearchBar, projectsCount }) => {
  const { platform } = usePlatform()
  const { pathname, push, query } = useRouter()
  const { t } = useTranslation('projects', { keyPrefix: 'headFilter' })
  const { t: tCommon } = useTranslation('common')
  const [scroll, setScroll] = useState(false)
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const keyword = extractString(query['keyword'])

  let typeAsString = extractString(query.type)

  if (typeAsString == 'presales' || typeAsString == 'rewards' || typeAsString == 'donation') {
    typeAsString = 'donation'
  } else {
    typeAsString = undefined
  }

  const filtersData = useMemo(() => getFilters(tCommon, platform, typeAsString), [platform, typeAsString])

  const goToMap = () => {
    // we strip out the page and keyword of the query on map page
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { page, keyword, ...restQuery } = query
    const newRoute = { pathname: '/map', query: { ...restQuery } }
    push(newRoute, undefined, { scroll: false })
  }

  const onSubmitSearch = (e: React.FormEvent) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      keyword: { value: string }
    }

    const filter = {
      ...query,
      order: state?.filters['order'],
      category: state?.filters['category'],
      impact: state?.filters['impact'],
      thematic: state?.filters['thematic'],
      type: state?.filters['type'],
      rewards: state?.filters['rewards'],
      region: state?.filters['region'],
      keyword: target?.keyword?.value,
      page: 1,
    }
    const url = { pathname, query: filter }

    return push(url)
  }

  const handleClearInput = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { keyword, ...newQuery } = query
    const newRoute = { pathname, query: { ...newQuery, page: 1 } }
    push(newRoute)
  }

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 72 && window.scrollY < (isMobile ? 5000 : 1300))
    })
  }, [isMobile])

  const state = useContext(FiltersContext)

  const mapButton = (
    <Text as="button" size="sm" width="120px" variant="underlined" onClick={goToMap} ml="4" name="mapButton">
      <PinMapOutline width="18px" color="dark_green" height="18px" mr="0.5" />
      {tCommon('see_map')}
    </Text>
  )

  return (
    <>
      <Container
        variant="full"
        bgColor="white"
        className={scroll ? styles.sticky : undefined}
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor="light_border"
      >
        <Container variant="boxed" py={8} flexDirection={isMobile ? 'column' : 'row'} justifyContent="flex-start">
          <Flex align="center">
            <QueryFilter
              filtersData={filtersData}
              totalResults={projectsCount}
              showResults={false}
              width="fit-content"
            />
            {isMobile && !pathname.includes('noel') && mapButton}
          </Flex>
          {withSearchBar && (
            <SearchBar
              w={{ base: '100%', lg: '90%' }}
              h="40px"
              my="10px"
              ml={{ base: '0', lg: '40px' }}
              onSubmit={onSubmitSearch}
              defaultValue={keyword ?? ''}
              allowEmptyValue
              placeholder={t('search-p')}
              onClearInput={handleClearInput}
            />
          )}
          {!isMobile && !pathname.includes('noel') && mapButton}
        </Container>
      </Container>
    </>
  )
}

export default HeadFilter
